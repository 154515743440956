<template>
  <div>
    <div class="d-flex align-items-center">
      <b-avatar
        rounded
        size="42"
        variant="light-info"
        class="mr-1"
      >
        <feather-icon
          icon="AlertCircleIcon"
          size="20"
        />
      </b-avatar>
      <div>
        <h4 class="mb-0">
          {{ $t('faq.title') }}
        </h4>
        <span>{{ $t('faq.description') }}</span>
      </div>
    </div>
    <app-collapse
      accordion
      type="margin"
      class="mt-2"
    >
      <app-collapse-item
        :is-visible="true"
        :title="$t('faq.copycode')"
      >
        <b-form-input
          ref="tarea"
          v-model="code"
          variant="primary"
          placeholder="Code"
        />
        <b-button
          v-clipboard:copy="code"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          v-b-tooltip.hover.top="$t('widgets.copybutton')"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-1"
        >
          <feather-icon icon="CopyIcon" /> {{ $t('form.copy') }}
        </b-button>
      </app-collapse-item>

    </app-collapse>
  </div>
</template>

<script>
import {
  BAvatar,
  BFormInput,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAvatar,
    AppCollapseItem,
    AppCollapse,
    BFormInput,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Code copied',
          icon: 'BellIcon',
        },
      })
      this.$refs.tarea.focus()
      this.$refs.tarea.select()
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy code!',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
