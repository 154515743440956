<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <DspPartnerToolbar
        v-if="Object.keys(dsppartner).length !== 0"
        :dsppartner="dsppartner"
        closing
      />
    </b-card>
    <faq :code="copiedCode()" />
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { BCard } from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import faq from './FaqDspPartner.vue'
import DspPartnerToolbar from './DspPartnerToolbar.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    faq,
    DspPartnerToolbar,
  },
  data() {
    return {
      breadcrumbItems: [],
      dsppartner: {},
    }
  },
  created() {
    this.getDspPartnerData()
  },
  methods: {
    async setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('DSP partners'),
          to: '/dsppartners/',
        },
        {
          text: this.dsppartner.name,
          active: true,
        },
      ]
    },
    async getDspPartnerData() {
      const responseData = await useJwt.getDsppartner(this.$route.params.id)
      this.dsppartner = responseData.data.dsppartner || {}
      this.setBreabcrumbs()
    },
    copiedCode() {
      return `https://dsp-eu.phoenix-widget.com/api/v1/ortb/${this.$route.params.id}/`
    },
  },
}
</script>
